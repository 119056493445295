.page {
  display: flex;
  height: 100vh;
}

.label {
  font-size: 1.5vw;
  color: #0d6986;
  letter-spacing: 0.2vw;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 1vw;
  padding: 1vw;
  background-color: #ffffff;
  border-radius: 5px;
}

.scrollableContent {
  flex: 1;
  display: flex;
  overflow-y: scroll;
}

.navigationLink {
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 1vw;
  font-weight: bold;
}

.activeNavLink {
  border-bottom: 2px solid #0d6986;
  color: #0d6986;
}

.inactiveNavLink {
  border-bottom: 2px solid #7fc6e6;
  color: #7fc6e6;
}

.controlPanel {
  justify-content: space-between;
  align-items: center;
  margin: 1vh 0;
  padding: 1vh;
  border-radius: 5px;
  background-color: #eeeeee;
}

.listViewContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1vh;
  border-bottom: 3px solid #eeeeee;
  height: 9vh;
}

.listViewDetails {
  display: flex;
  justify-content: space-between;
  width: 35vw;
}

.listViewLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 40px;
}

.listViewImage {
  max-width: 70px;
  max-height: 35px;
}

.listViewName {
  display: flex;
  align-items: center;
  width: 20vw;
}

.viewItemLabel {
  color: #707070;
  font-weight: bold;
  font-size: 1.1vw;
}

.listViewStatus {
  width: 5vw;
  padding: 1vh 1vw;
  text-align: center;
  font-size: 0.8vw;
  font-weight: bold;
  border-radius: 5px;
}

.cardViewContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  border-radius: 5px;
  border: 4px solid #eeeeee;
}

.cardViewLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9vw;
}

.cardViewImage {
  max-width: 8vw;
  max-height: 20vh;
}

.cardViewControls {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2vw;
}
