.header {
  display: flex;
  align-items: center;
  background-color: #eefcff;
  height: 5vh;
  padding-left: 5px;
  color: #0d6986;
  font-weight: bold;
  font-size: 0.9vw;
  margin-bottom: 1vh;
}

.item {
  display: flex;
  padding-left: 5px;
}

.message {
  font-weight: bold;
  font-size: 0.8vw;
  margin: 2vh 0;
  color: #0d6986;
}

.hintLabel {
  font-weight: bold;
  font-size: 0.8vw;
}

.separator {
  border: 1px solid #eeeeee;
}

.button {
  text-decoration: none;
  border-radius: 5px;
  background-color: #0d6986;
  color: white;
  font-weight: bold;
  font-size: 0.8vw;
  padding: 1vh 1vw;
}
