.label {
  color: #0d6986;
  font-weight: bold;
  font-size: 1.5vw;
}

.itemLabel {
  color: #0d6986;
  font-weight: bold;
  font-size: 1vw;
}

.button {
  font-weight: bold;
  font-size: 1vw;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  padding: 1vh 1vw;
  background-color: #0d6986;
}

.createContent {
  padding: 1vw;
  border-radius: 5px;
  border: 1px solid #bbbbbb;
}

.createInput {
  flex: 1;
  display: flex;
  padding-right: 15vw;
}

.createControl {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 2vh;
}
