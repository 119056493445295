/* common style */

.startedButton {
  margin-left: 1.5vw;
  padding: 1vh 1.5vw;
  background-color: #0d6986;
  border-radius: 15px;
  text-decoration: none;
  font-size: 1.3vw;
  color: #ffffff;
}

/* front content style */

.frontContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10vh;
  height: 90vh;
}

.frontHeaderLabel {
  font-size: 4vw;
  font-weight: bold;
  color: #0d6986;
}

.frontMessageLabel {
  font-size: 2.5vw;
  color: #0d6986;
}

/* get starting content style */

.startingContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2vh;
}

.startingLabelContent {
  margin-top: 2vh;
  width: 50vw;
  display: flex;
  justify-content: space-between;
}

.startingLabel {
  font-size: 2.5vh;
  color: #0d6986;
}

.startingBorder {
  border: 0.1vh solid #0d6986;
}

/* efficiency content style */

.efficiencyContent {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eefcff;
}

.efficiencyHeaderLabel {
  font-size: 5vh;
  font-weight: bold;
  color: #0d6986;
}

.efficiencyMessageLabel {
  font-size: 3vh;
  color: #0d6986;
}

.efficiencyElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 48vh;
  width: 26vw;
  border-radius: 15px;
}

.efficiencyElementMessage {
  font-size: 2.8vh;
  font-weight: bold;
  color: white;
}

.efficiencyImageContent {
  flex: 1;
  display: flex;
  align-items: center;
}

.efficiencyElementFooter {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 26vw;
  height: 6vh;
  margin-top: 2vh;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.efficiencyLearnMoreButton {
  margin-right: 1vw;
  text-decoration: none;
  font-size: 1.5vh;
  color: white;
  border: 1px solid white;
  padding: 0.5vh 1.5vw;
  border-radius: 10px;
}

/* benefits content style */

.benefitsContent {
  display: flex;
  justify-content: center;
  background-color: #0d6986;
}

.benefitsHeaderLabel {
  color: white;
  font-size: 5vh;
  font-weight: bold;
}

.benefitsMessageLabel {
  color: white;
  font-size: 4vh;
  font-weight: bold;
}

.benefitsListLabel {
  color: white;
  font-size: 2.5vh;
  font-weight: bold;
  margin-bottom: 2vh;
}

.benefitsLearnMoreButton {
  text-decoration: none;
  font-size: 2vh;
  font-weight: bold;
  color: white;
  border: 1px solid white;
  padding: 1.5vh 2vw;
  border-radius: 15px;
  margin-top: 5vh;
}

/* advantages content style */

.advantagesContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;
}

.advantagesGridElement {
  height: 55vh;
  display: flex;
}

.advantageElement {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.advantagePanel {
  display: flex;
  align-items: center;
  height: 3vh;
  padding-left: 1vw;
}

.advantagePanelElement {
  width: 1vh;
  height: 1vh;
  border-radius: 1vh;
  margin-right: 0.5vw;
}

.advantageElementContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3vh;
  background-color: #eefcff;
}

.advantageElementLink {
  font-size: 2.3vh;
  color: #5ea6c5;
}

.advantageElementHeader {
  font-size: 3.5vh;
  color: #0d6986;
  font-weight: bold;
}

.advantageElementMessage {
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-size: 3vh;
  color: #5ea6c5;
  font-weight: bold;
}

.advantagesGridLearnMore {
  display: flex;
  align-items: end;
  justify-content: end;
  height: 10vh;
}

.advantageLearnMoreButton {
  text-decoration: none;
  font-size: 2.5vh;
  font-weight: bold;
  color: #0d6986;
  border: 2px solid #0d6986;
  padding: 1.5vh 2vw;
  border-radius: 15px;
}
