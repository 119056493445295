.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.6vw;
  font-weight: bold;
  text-decoration: none;
}

.active {
  color: #0d6986;
}

.inactive {
  color: #707070;
}
