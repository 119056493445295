.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh;
  border-radius: 5px;
  background-color: #eeeeee;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  border: 2px solid #eeeeee;
}

.cardContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.label {
  color: #0d6986;
  font-weight: bold;
  font-size: 1.2vw;
}

.currencyLabel {
  font-size: 0.8vw;
  padding-right: 3px;
}

.presence {
  text-decoration: none;
  color: #0d6986;
  font-weight: bold;
}

.absence {
  text-decoration: none;
  color: #bbbbbb;
  font-weight: bold;
  font-size: 1vw;
}

.element {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vw 0 1vw 0;
}

.cardControl {
  border-top: 2px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 10px;
}
