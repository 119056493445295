.page {
  display: flex;
  height: 100vh;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
}

.header {
  display: flex;
  align-items: end;
  height: 3.5vw;
  padding: 0 1vw;
}

.label {
  font-size: 1.5vw;
  color: #0d6986;
  letter-spacing: 0.2vw;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 1vw;
  padding: 1vw;
  background-color: #ffffff;
  border-radius: 5px;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh;
  border-radius: 5px;
  background-color: #eeeeee;
}

.scrollable {
  display: flex;
  overflow-y: scroll;
  margin-top: 1vw;
  border: 2px solid #E0E0E0;
  border-radius: 10px;
}

.headerLabel {
  font-weight: bold;
  font-size: 0.8vw;
  color: #333333;
  padding: 1.5vh 1vh;
  background-color: #F1F1F1;
}

.rowLabel {
  display: flex;
  align-items: center;
  color: #333333;
  padding: 1vh;
}

.rowLine {
  height: 2px;
  background-color: #E0E0E0;
}

.headerComponent {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.name {
  color: #333333;
  font-weight: bold;
  font-size: 20px
}

.button {
  align-self: flex-end;
  font-weight: bold;
  font-size: 0.8vw;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  padding: 1vh 1vw;
  background-color: #0d6986;
}

.metadata {
  width: 15vw;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid #E0E0E0;
}

.metadataLabel {
  color: #757575;
  font-weight: bold;
  font-size: 0.7vw;
}

.metadataValue {
  color: #333333;
  font-weight: bold;
  font-size: 0.9vw;
}

.accountRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 2px solid #E0E0E0;
}

.accountComponent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3vw;
}

.accountNameLabel {
  margin-top: 5px;
  color: #333333;
  font-weight: bold;
  font-size: 1vw;
  text-align: center;
}

.accountPhoneLabel {
  margin-top: 5px;
  color: #757575;
  font-weight: bold;
  font-size: 0.8vw;
  text-align: center;
}

.accountAddressLabel {
  margin-top: 5px;
  color: #757575;
  font-weight: bold;
  font-size: 0.8vw;
  text-align: center;
}

.listingSummary {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-right: 1vw;
}

.listingSummaryLabel {
  color: #333333;
  font-weight: bold;
  font-size: 0.8vw;
}

.itemRow {
  flex: 1;
  display: flex;
  padding-right: 1vw;
}

.attributesComponent {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 1vh;
}

.itemNameLabel {
  font-size: 1vw;
  font-weight: bold;
  color: #333333;
}

.itemDescriptionLabel {
  margin-top: 1vh;
  font-size: 0.8vw;
  color: #757575;
}
