.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35vh;
  width: 13.95vw;
}

.name {
  padding: 5px;
  color: #5ea6c5;
  font-size: 0.9vw;
  font-weight: bold;
}

.outOfStock {
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: #ffffff;
  padding: 5px 10px;
  font-size: 0.7vw;
  color: #0d6986;
  font-weight: bold;
  border-radius: 5px;
}

.itemContent {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
}

.textItem {
  text-decoration: none;
  margin: 5px;
  padding: 0.5vh 1vw;
  font-weight: bold;
  color: #bbbbbb;
  border: 1px solid #bbbbbb;
  background-color: #ffffff;
  border-radius: 5px;
}

.imageItem {
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #bbbbbb;
}

.content {
  display: flex;
  flex-direction: column;
  height: 9vh;
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
}

.control {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.pricing {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.pricing .label {
  padding-left: 5px;
  font-size: 1vw;
  font-weight: bold;
  color: #0d6986;
}
