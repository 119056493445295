.cover {
}

.cover .picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 15vw;
  height: 15vw;
  margin-right: 1vw;
  border-radius: 5px;
  background-image: linear-gradient(to left, #bbbbbb 50%, transparent 50%),
    linear-gradient(to left, #bbbbbb 50%, transparent 50%),
    linear-gradient(to top, #bbbbbb 50%, transparent 50%),
    linear-gradient(to top, #bbbbbb 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
  background-color: #eeeeee;
}

.cover .label {
  font-size: 0.9vw;
  font-weight: bold;
  color: #bbbbbb;
  letter-spacing: 0;
}

.additional {
}

.additional .picker {
  text-decoration: none;
  border-radius: 5px;
  background-color: #0d6986;
  color: white;
  font-weight: bold;
  font-size: 0.8vw;
  padding: 1vh 1vw;
}

.chroma {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
