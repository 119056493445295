.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  padding-bottom: 10vh;
}

.headerLabel {
  color: #0d6986;
  font-size: 2.5vw;
  font-weight: bold;
}

.messageLabel {
  color: #0d6986;
  font-size: 1.7vw;
}

.panel {
  height: 3vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.planContent {
  display: flex;
  flex-direction: column;
  width: 25vw;
  height: 37vw;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 10vh;
}

.planLabel {
  text-align: center;
  margin-top: 1vw;
  padding: 0.2vw 0;
  border-radius: 7px;
  color: white;
  font-size: 1.5vw;
  font-weight: bold;
}

.planPrice {
  color: #0d6986;
  font-size: 2vw;
  font-weight: bold;
}

.planPeriod {
  color: #707070;
  opacity: 50%;
  font-size: 1vw;
  font-weight: bold;
}

.planMessage {
  color: #0d6986;
  font-size: 0.9vw;
  margin-top: 2vh;
  min-height: 5vw;
}

.startedButton {
  margin-top: 3vh;
  padding: 0.5vw 0;
  border-radius: 5px;
  text-align: center;
  align-self: center;
  width: 17vw;
  border: 2px solid #0d6986;
  text-decoration: none;
  font-size: 0.9vw;
  font-weight: bold;
  color: #0d6986;
}
