.page {
  display: flex;
  height: 100vh;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 3.5vw;
  padding: 0 1vw;
}

.headerControls {
  width: 9vw;
  height: 3.5vw;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.label {
  font-size: 1.5vw;
  color: #0d6986;
  letter-spacing: 0.2vw;
}

.button {
  text-decoration: none;
  border-radius: 5px;
  background-color: #0d6986;
  color: white;
  font-weight: bold;
  font-size: 0.8vw;
  padding: 1vh 1vw;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 1vw;
  padding: 1vw;
  background-color: #ffffff;
  border-radius: 5px;
}

.scrollableContent {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  margin-top: 1vw;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh;
  border-radius: 5px;
  background-color: #eeeeee;
}

.scrollable {
  overflow-y: scroll;
}

.component {
  display: flex;
  flex-direction: column;
  padding: 1vw;
  border: 3px solid #eeeeee;
  border-radius: 10px;
}

.component .label {
  font-size: 1.1vw;
  font-weight: bold;
  color: #bbbbbb;
  letter-spacing: 0.1vw;
}

.component .text {
  font-size: 0.9vw;
  font-weight: bold;
  color: #707070;
  margin-top: 2vh;
}

.empty {
  flex: 1;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty .label {
  font-style: italic;
  font-size: 1.6vw;
  color: #0d6986;
  text-align: center;
  letter-spacing: normal;
}

.filter {
  flex: 1;
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 10px;
}

.filterComponent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
}

.filterLabel {
  color: #0d6986;
  font-weight: bold;
  font-size: 1vw;
}

.filter .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  font-size: 0.9vw;
  font-weight: bold;
  margin-bottom: 1vh;
  border-radius: 5px;
  text-decoration: none;
}
