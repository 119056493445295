.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5vh;
}

.logo {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 5vw;
  height: 3.5vw;
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5vw;
}

.control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5vw;
}
