.header {
  height: 5vw;
  width: 96vw;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding-left: 2vw;
  padding-right: 2vw;
  background-color: white;
  border-bottom: 1px solid #7fc6e6;
}

.logoLabel {
  text-decoration: none;
  font-size: 2vw;
  color: #0d6986;
  letter-spacing: 0.2vw;
}

.menu {
  display: flex;
  align-items: center;
  height: 5vw;
}

.link {
  margin-left: 1.5vw;
  text-decoration: none;
  color: #0d6986;
  font-size: 1.3vw;
}

.activeLink {
  margin-left: 1.5vw;
  text-decoration: none;
  color: #0d6986;
  font-size: 1.3vw;
  font-weight: bold;
}

.button {
  margin-left: 1.5vw;
  padding: 1vh 1.5vw;
  background-color: #0d6986;
  border-radius: 15px;
  text-decoration: none;
  font-size: 1.3vw;
  color: #ffffff;
}
