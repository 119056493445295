.body {
  background-color: #eefcff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dialog {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 55vw;
  height: 65vh;
  border: 1px #707070 solid;
  border-radius: 10px;
}

.navigationGroup {
  display: flex;
  justify-content: space-between;
  padding: 5vh 3vw 1vh 3vw;
}

.stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bulletGroup {
  display: flex;
  width: 100%;
}

.bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #707070;
  margin-bottom: 1vh;
}

.line {
  flex: 1;
  margin-top: 1vh;
  height: 2px;
  background-color: #BFF9E4;
}

.buttonNavGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5vh;
}

.button {
  font-size: 15px;
  padding: 5px 20px;
  color: white;
  background-color: #0d6986;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 3vw;
}

.formGroup {
  flex: 1;
  display: flex;
}

.inputGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}