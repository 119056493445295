.content {
  display: flex;
  flex-direction: column;
  background-color: #eefcff;
  padding: 10vh;
}

.gridItem {
  display: flex;
}

.gridItemContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.label {
  text-decoration: none;
  color: #0d6986;
  font-weight: bold;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.socialMediaContent {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  width: 10vw;
}
