.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.tooltip {
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: #ffffff;
  padding: 3px;
  border-radius: 15px;
}

.message {
  display: flex;
  flex-direction: column;
  height: 10vh;
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
}

.name {
  padding: 5px;
  color: #5ea6c5;
  font-size: 1vw;
  font-weight: bold;
}

.description {
  padding: 0 5px 5px 5px;
  color: #5ea6c5;
  font-size: 0.8vw;
  text-overflow: clip;
}

.controls {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  right: 3px;
  top: 3px;
}
