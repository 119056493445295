.imageContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1vw;
}

.cover {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollableImages {
  flex: 1;
  display: flex;
  align-items: flex-end;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
}

.image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 7vw;
  min-height: 7vw;
  border-radius: 5px;
}

.detailsContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.name {
  color: #0d6986;
  font-weight: bold;
  font-size: 2vw;
}

.itemName {
  color: grey;
  font-weight: bold;
  font-size: 1.5vw;
}

.description {
  flex: 1;
  color: #0d6986;
  font-size: 1.2vw;
  margin-top: 2vh;
}

.inlineComponent {
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
}

.retailPeriod {
  color: #0d6986;
  font-size: 1.3vw;
  font-weight: bold;
}

.showcase {
  color: #0d6986;
  font-size: 1.3vw;
  font-weight: bold;
}

.scrollableItems {
  display: flex;
  margin-top: 1vw;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
}

.imageItem {
  max-width: 5vw;
  max-height: 5vw;
  border-radius: 5px;
}

.textItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: 5vw;
  background-color: #eeeeee;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 1vw;
}

.button {
  align-self: flex-end;
  margin-top: 1vw;
  font-weight: bold;
  font-size: 1vw;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  padding: 1vh 2vw;
  background-color: #0d6986;
}
